$primary-bg-color: #ED1C24;
$primary-font-color: #DEDEDE;

$secondary-bg-color: #D8D8D8;
$secondary-font-color: #FF1019;

$spacing-section: 20px;

$section-spacing-mobile: 5vw;
$section-spacing: 5vw;

$font-size-heading: 24px;

// Animation standard from Material guideline

// This is the most common easing curve.
$easeInOut: cubic-bezier(0.4, 0, 0.2, 1);
// Objects enter the screen at full velocity from off-screen and
// slowly decelerate to a resting point.
$easeOut: cubic-bezier(0, 0, 0.2, 1);
// Objects leave the screen at full velocity. They do not decelerate when off-screen.
$easeIn: cubic-bezier(0.4, 0, 1, 1);
// The sharp curve is used by objects that may return to the screen at any time.
$sharp: cubic-bezier(0.4, 0, 0.6, 1);

// Follow https://material.io/guidelines/motion/duration-easing.html#duration-easing-common-durations
// to learn when use what timing
$shortest: 150ms;
$shorter: 200ms;
$short: 250ms;
// most basic recommended timing
$standard: 300ms;
// this is to be used in complex animations
$complex: 375ms;
// recommended when something is entering screen
$enteringScreen: 225ms;
// recommended when something is leaving screen
$leavingScreen: 195ms;




@mixin mobile-breakpoint {
  @media (max-width: 700px) {
    @content;
  }
}

body {
  padding: 0;
  margin: 0;
  font-size: 15px;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  line-height: 1.5em;
  color: $primary-font-color;
  background-color: $primary-bg-color;
}

* {
  box-sizing: border-box;
}

h1 {
  font-size: 24px;
  font-weight: normal;
  margin-top: 0px;
  line-height: 1.25em;

  small {
    font-size: 20px;
  }
}

a {
  text-decoration: none;
}

// site anchor link
a[name] {
  position: relative;
  top: -60px;
}

@include mobile-breakpoint {
  a[name] {
    position: relative;
    top: -5vh;
  }
}


.light_background {
  background-color: $secondary-bg-color;
  color: $secondary-font-color;
}

.invert_background {
  color: $primary-font-color;
  background-color: $primary-bg-color;
}

.section {
  padding: $section-spacing;

  @include mobile-breakpoint {
    padding: $section-spacing-mobile;
  }
}

.btn {
  display: inline-block;
  background-color: $secondary-bg-color;
  color: $secondary-font-color;
  padding: 0.5em 0.75em;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.btn_invert {
  color: $primary-font-color;
  background-color: $primary-bg-color;
}

.mobile_show {
  display: none;

  @include mobile-breakpoint {
    display: block;
  }
}

.mobile_hide {
  @include mobile-breakpoint {
    display: none;
  }
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}


.btn_unstyled {
  text-decoration: none;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
}
