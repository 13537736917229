.menu {
  display: none;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;

  @include mobile-breakpoint {
    display: block;
    position: fixed;
    top: $section-spacing-mobile;
    right: $section-spacing-mobile;
    z-index: 200;
  }
}

.site_header {
  position: fixed;
  top: 0;
  left: 0;
  padding: 1.25em 0;
  width: 100%;
  z-index: 100;
  background: transparent;

  nav {
    position: relative;

    a {
      color: $primary-font-color;
      display: inline-block;
      padding: 0 1em;
      visibility: hidden;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .logo {
    $width: 126px;
    $height: 29px;

    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -0.5 * $width;
    margin-top: -0.5 * $height;
    cursor: pointer;
    z-index: 100;
  }

  .left {
    float: left;
  }

  .right {
    float: right;
  }

  .logo .fill_part {
    fill: $primary-font-color;
  }

  .social_sharing {
    display: none;
  }

  .background {
    display: none;
  }

  &:hover {
    color: $primary-font-color;
    background-color: $primary-bg-color;

    a {
      visibility: visible;
    }
  }

  @include mobile-breakpoint {
    $spacing: $font-size-heading;

    padding: 0;
    background-color: $primary-bg-color;

    .menu {
      display: block;
      position: absolute;
      top: $section-spacing-mobile;
      right: $section-spacing-mobile;
    }

    .logo_container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: $spacing;
    }

    .logo {
      top: 0;
      left: 0;
      position: static;
      margin-top: 0;
      margin-left: 0;
    }

    .left, .right {
      float: none;
    }

    .nav {
      display: block;
      width: 100%;
    }

    nav {
      flex-direction: column;
      align-items: center;
      margin-top: $spacing;

      a {
        display: block;
        text-align: center;
        width: 100%;
        font-size: $font-size-heading;
        padding: (0.5 * $spacing) 0;
        visibility: visible;
      }
    }

    .logo {
      display: block;
    }

    .social_sharing {
      display: block;
      text-align: center;
      margin-top: $spacing;
      margin-bottom: $spacing;

      a {
        display: inline-block;
        margin: 0 (0.5 * $spacing);
      }
    }
  }
}

@include mobile-breakpoint {
  .expansion_header {
    height: 0;
    transition: height $short $easeInOut;
    overflow: hidden;
  }
}


.mobile_logo_container {
  position: absolute;
  top: $font-size-heading;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: -1;
  visibility: hidden;
  width: 100%;

  .logo {
    cursor: pointer;
  }

  @include mobile-breakpoint {
    z-index: 100;
    visibility: visible;
  }
}

.social_sharing_sidebar {
  position: fixed;
  top: 50%;
  left: 1em;
  margin-top: -100px;
  background: transparent;
  z-index: 100;

  a {
    display: block;
    margin-bottom: 1em;
    cursor: pointer;
  }

  @include mobile-breakpoint {
    display: none;
  }
}
